<template lang="pug">
q-dialog(
    full-width
    position="bottom"
    transition-show="slide-up"
    transition-hide="slide-down"
    ref="dialog")
    .row.justify-start.full-width.bg-secondary.relative-position.help-mobile.text-primary.ios-padding-bottom
        .help-mobile__header.flex.items-center.justify-between
            .text-weight-medium.text-positive.q-mx-md
                | Обратная связь
            q-btn.z-top.q-mx-sm(
                flat
                round
                dense
                v-close-popup
            )
                q-icon(
                    size="xs"
                    name="etabl:cross"
                    color="positive"
                )
        .q-ma-md
            .font-12.text-grey-9.q-mb-xs
                | Быстрые ссылки
            .help-mobile__links.bg-grey-5.b-radius-16(style="height: fit-content;")
                .q-py-sm.q-px-md.row.justify-between(@click="goToPage('/faq')")
                    .span.text-positive
                        | Вопрос/Ответ
                    q-icon(
                        size="20px"
                        name="etabl:arrow_next"
                        color="positive"
                    )
            .q-mt-sm.q-mb-md
                | У вас возникли вопросы, выберите удобный способ для связи с нами
            .row.justify-between
                .col-6.q-pr-sm
                    base-btn(
                        bradius="16"
                        height="36"
                        label="Написать"
                        color="secondary"
                        border="1px solid #0CA551"
                        text_color="positive"
                        icon_color="positive"
                        icon="dialog"
                        @click="goChat")
                .col-6.q-pl-sm
                    base-btn(
                        bradius="16"
                        height="36"
                        label="Позвонить"
                        color="secondary"
                        border="1px solid #0CA551"
                        text_color="positive"
                        icon_color="positive"
                        icon="phone"
                        @click.prevent="goCall")
.column.items-center.justify-center.button.footer(@click.prevent.stop="show")
    q-icon(
        name="etabl:chat-round"
        size="26px"
        color="green-5"
    )
    .font-12.text-green-5 Чат
</template>

<script>
import { useQuasar } from 'quasar';
import { ref, computed, watch, getCurrentInstance, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import md5 from 'src/assets/helpers/md5.js';
import { convertGender } from 'src/assets/helpers';
import BaseBtn from 'components/Base/BaseBtn.vue';

export default {
    name: 'FloatBtnMobile',
    components: {
        BaseBtn,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const q = useQuasar();

        const dialog = ref(null);
        const isActive = ref(false);
        const showChat = ref(false);
        const newMessages = ref(0);
        let initChat = false;
        const chatLoad = ref(false);

        // данные пользователя
        const userInfo = computed(() => store.getters['user/USER_INFO']);

        // E-mail пользователя
        const userEmail = computed(() => store.getters['user/USER_EMAIL']);

        // Телефона пользователя
        const userPhone = computed(() => store.getters['user/USER_PHONE']);

        // uid пользователя
        const userUid = computed(() => store.getters['user/USER_UID']);

        // id пользователя
        const userId = computed(() => store.getters['user/USER_ID']);

        watch(() => route, () => {
            if (isActive.value) {
                isActive.value = false;
            }
        });

        const getIp = async () => {
            return new Promise((resolve) => {
                fetch('https://httpbin.org/ip')
                    .then((response) => {
                        resolve(response.json());
                    })
                    .catch(() => {
                        resolve({
                            origin: 'Не удалось определить...',
                        });
                    });
            });
        };

        const goChat = async () => {
            if(q.platform.is.cordova && q.platform.is.ios)
                cordova.InAppBrowser.open('https://www.artes-grp.ru/online/fartes', '_blank', 'closebuttoncolor=#ffffff,toolbarcolor=#0ca551,toolbarposition=top,lefttoright=yes,hidenavigationbuttons=yes,disallowoverscroll=yes,closebuttoncaption=Закрыть');
            else {
                const user_data = {
                    name: userInfo.value?.name || 'Незарегистрированный пользователь',
                    patronymic: userInfo.value?.patronymic || null,
                    gender: userInfo.value?.gender || null,
                    email: userEmail.value?.email_address || null,
                    phone: userPhone.value?.phone_number || null,
                };

                if (process.env.CLIENT) {
                    if (Object.keys(window.widget.userRegisterData).length === 0) {
                        const { origin: ip } = await getIp();

                        if (!userUid.value) {
                            await store.dispatch('user/SET_UID');
                        }

                        window.widget.setUserRegisterData({
                            'hash': md5(userUid.value).toLowerCase(),
                            'name': user_data.name,
                            'lastName': user_data.patronymic,
                            'gender': user_data.gender,
                            'email':user_data.email,
                        });

                        window.widget.setCustomData(`
                            ID пользователя: ${userUid.value}
                            IP пользователя: ${ip}
                            Имя: ${user_data.name || ''}
                            Отчество: ${user_data.patronymic || ''}
                            Пол: ${user_data.gender ? convertGender(user_data.gender) : ''}
                            Номер телефона: ${user_data.phone || ''}
                            E-mail: ${user_data.email || ''}
                        `);
                    }

                    if (userId.value && userId.value !== userUid.value) {
                        store.commit('user/SET_UID', userId.value);

                        window.widget.setUserRegisterData({
                            'hash': md5(userUid.value).toLowerCase(),
                            'name': user_data.name,
                            'lastName': user_data.patronymic,
                            'gender': user_data.gender,
                            'email':user_data.email,
                        });
                    }

                    window.widget.open();
                    hide();
                }
            }

            if (process.env.CLIENT) {
                // window._paq.push(['trackGoal', 21]);
            };
        };

        const newMessage = () => {
            if (!showChat.value) {
                newMessages.value++;
            }
        };

        const goToPage = (page) => {
            router.push(page);
            hide();
        };

        const goCall = () => {
            if (process.env.CLIENT) {
                window.location.replace('tel:+78002005400');
            }

            if (process.env.CLIENT) {
                // window._paq.push(['trackGoal', 20]);
            };
        };

        const show = () => {
            dialog.value.show();

            if(!chatLoad.value) {
                try {
                    const u = 'https://www.artes-grp.ru/upload/crm/site_button/loader_1_w754r9.js';

                    var s = document.createElement('script'); 
                    
                    s.async = true; 
                    
                    s.src = u + '?' + (Date.now() / 60000 | 0);

                    var h = document.getElementsByTagName('script')[0];
                    
                    h.parentNode.insertBefore(s, h);

                    if (process.env.CLIENT) {
                        window.addEventListener('onBitrixLiveChat', (event) => {
                            if (event.detail.widget) {
                                window.widget = event.detail.widget;

                                window.widget.buttonInstance.container.style.display = 'none';

                                if (window.mutateTemplateComponent) {
                                    window.mutateTemplateComponent('bx-livechat-form-welcome', { template: '<div></div>' });
                                }
                            }
                        });
                    }
                } catch (e) {}

                chatLoad.value = true;
            };
        };

        const hide = () => {
            dialog.value.hide();
        };

        return {
            dialog,
            goChat,
            goCall,
            show,
            hide,
            showChat,
            isActive,
            goToPage,
            newMessages,
            chatLoad,
        };
    },
};
</script>

<style lang="scss" scoped>
.button {
    width: 30px;
    height: 30px;
    z-index: 5100;
    background-color: #fbfafa;
    border-radius: 10px;
}

.pulse {
   animation: pulse-43bdfc71 2s infinite; 
}

.footer {
    width: 16.6667%;
    height: 100%;
    background-color: $secondary;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(34, 189, 119, 1);
  }
  70% {
      -webkit-box-shadow: 0 0 0 50px rgba(234, 189, 119, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(34, 189, 119, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(34, 189, 119, 1);
    box-shadow: 0 0 0 0 rgba(34, 189, 119, 1);
  }
  70% {
      -moz-box-shadow: 0 0 0 50px rgba(34, 189, 119, 0);
      box-shadow: 0 0 0 10px rgba(34, 189, 119, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(34, 189, 119, 0);
      box-shadow: 0 0 0 0 rgba(34, 189, 119, 0);
  }
}

.help-mobile {
    border-radius: 16px 16px 0px 0px;

    &__header {
        height: 50px;
        width: 100%;
        background: #D6F4E4;
    }

    &__links {
        height: 111px;
    }
}

.q-badge {
    width: 14px;
    height: 14px;
    position: absolute;
    padding-top: 4px;
}
</style>

<template lang="pug">
.full-width.fixed-bottom.row.footer.bg-secondary(
    :class="showShadow ? 'footer__shadow' : ''")
    template(v-for="item in items")
        router-link.col-2.column.items-center(
        v-if="item.type == 'link'"
        :to="city ? `/${city.slug}${item.link}` : item.link"
        :title="`${item.label}`")
            .relative-position
                q-icon(
                    :name="`etabl:${item.icon}`"
                    :color="item.active ? 'positive' : 'green-5'"
                    size="26px")
                    q-badge(
                        v-if="item.count"
                        rounded
                        floating
                        color="negative"
                        :style="'top: -4px; right: -10px;'")
                        span.full-width.text-weight-bold.roboto.font-12 {{ item.count }}
            .font-12(:class="item.active ? 'text-positive' : 'text-green-5'") {{ item.title }}
        .col-2.column.items-center(
            v-else-if="item.type == 'button'"
            @click="item.method()")
            .relative-position
                q-icon(
                    :name="`etabl:${item.icon}`"
                    :color="item.active ? 'positive' : 'green-5'"
                    size="26px")
                    q-badge(
                        v-if="item.count != 0"
                        rounded
                        floating
                        color="negative"
                        :style="'top: -4px; right: -10px;'")
                        span.full-width.text-weight-bold.roboto.font-12 {{ item.count }}
            .font-12(:class="item.active ? 'text-positive' : 'text-green-5'") {{ item.title }}
    help-mobile   
</template>

<script>
import { useQuasar } from 'quasar';
import { computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import HelpMobile from 'components/Help/HelpMobile.vue';

export default {
    name: 'FooterMobile',
    components: {
        HelpMobile,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const $q = useQuasar();

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);
        const basketCount = computed(() => {
            return userAuthorized.value
                ? store.getters['basketNew/BASKET_COUNT']
                : process.env.CLIENT ? store.getters['localStorage/BASKET_COUNT'] : 0;
        });

        // общее кол-во товаров в корзине
        const count = computed(() => userAuthorized.value
            ? store.getters['basket/BASKET_COUNT']
            : store.getters['localStorage/BASKET_COUNT']);

        const countOfActiveOrders = computed(() => store.getters['user/USER_COUNT_ACTIVE_ORDERS']);

        const showShadow = computed(() => {
            if (route.name == 'basket' && !count.value)
                return true;

            if(route.name == 'basket') {
                return false;
            }

            return route.name !== 'Product' && route.name !== 'Basket';
        });

        const showAuth = () => {
            store.commit('auth/SET_ENTRY_AUTH', 'signin');
        };

        const goCatalog = () => {
            router.push({ path: '/catalog-list-mobile' });
        };

        const items = computed(() => [
            {
                icon: 'mobile_home',
                title: 'Главная',
                count: 0,
                type: 'link',
                link: '/',
                active: route.name == 'Home',
            }, {
                icon: 'mobile_catalog',
                title: 'Каталог',
                count: 0,
                type: 'link',
                link: '/catalog-list-mobile',
                active: route.name == 'catalog-list-mobile' || route.name == 'catalog',
            }, {
                icon: 'mobile_basket',
                title: 'Корзина',
                count: basketCount.value,
                type: 'link',
                link: '/basket',
                active: route.name == 'Basket',
            }, {
                icon: 'mobile_orders',
                title: ' Заказы',
                count: countOfActiveOrders.value,
                type: 'link',
                link: '/o',
                active: route.name == 'orders-mobile',
            }, {
                icon: 'mobile_profile',
                title: 'Профиль',
                count: userAuthorized.value && !!promocodesCount.value ? promocodesCount : '',
                type: userAuthorized.value ? 'link' : 'button',
                link: '/personal-area',
                method: showAuth,
                active: route.name == 'personal-area',
            },
        ]);

        // промокоды
        const promocodes = computed(() => store.getters['basketNew/PROMOCODES']);

        const promocodesCount = computed(() => {
            if (userAuthorized.value && promocodes?.value?.length) {
                return promocodes.value.length;
            } else {
                return false;
            }
        });

        onMounted(async () => {
            // если пользователь авторизован - получаем кол-во активных заказов
            if (userAuthorized.value) {
                await store.dispatch('user/USER_GET_COUNT_ACTIVE_ORDERS');
            }
        });

        return {
            items,
            goCatalog,
            promocodes,
            showShadow,
            basketCount,
            promocodesCount,
            city,
        };
    },
};
</script>

<style lang="scss" scoped>
.footer {
    padding-top: 12px;
    min-height: 60px;
    z-index: 100;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);

    & > a {
        text-decoration: none;
    }

    &__shadow {
        border-radius: $mobile-border-radius $mobile-border-radius 0 0;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    }
}
</style>
